import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as userActions from "../actions/User";
import Pagesidebar from "../components/Pagsidebar";
import {
    BarChart,
    Cell,
    LabelList,
    Bar,
    XAxis,
    ResponsiveContainer,
} from 'recharts';
import {kycStore} from '../reducers/KYCState';
import {userService} from "../services/user.Services";
import Loader from "../components/Loader";
import Pagination from "react-js-pagination";
import Navbar from "../components/Navbar";
import Selectbox from "../components/Selectbox";


class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state={
            activeFilter: 'completed',
            activeStage: 'all',
            nationality: 'all',
            ActivePage: 1,
            perpage: '50'
        };

        this.checkConfig = this.checkConfig.bind(this);
        this.chooseFilter = this.chooseFilter.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }
    componentDidMount() {
        //  userService.getConfig();
        setTimeout(this.checkConfig, 100)
        if(!localStorage.getItem('id_token')){
            window.location = "/";
        }

    }

    checkConfig() {

        if (!kycStore.getState().KYCState.DOB_API) {
            userService.getConfig();
            setTimeout(this.checkConfig, 100)
        } else {
            this.props.listboarddata('',this.state.activeFilter,this.state.nationality,this.state.perpage,this.state.ActivePage, this.state.activeStage);
            this.props.listchartdata(this.state.activeFilter);
            //this.props.listexportdata('1',this.state.activeFilter,'all');
        }
    }

    chooseFilter = (type) =>{
        this.setState({activeFilter: type, ActivePage: 1})
        this.props.listboarddata('',type,this.state.nationality,this.state.perpage,this.state.ActivePage, this.state.activeStage);
        this.props.listchartdata(type);
    }
    chooseNationality = (type) =>{
        this.setState({nationality: type, ActivePage: 1})
        this.props.listboarddata('',this.state.activeFilter,type,this.state.perpage,this.state.ActivePage, this.state.activeStage);
    }
    handlePageChange = (pageNumber) =>{
        this.setState({ActivePage:pageNumber});
        this.props.listboarddata('',this.state.activeFilter,this.state.nationality,this.state.perpage,pageNumber, this.state.activeStage);
    }
    handlestageFilter = (value) => {
        this.setState({activeStage: value, ActivePage: 1})
        this.props.listboarddata('', this.state.activeFilter,this.state.nationality,this.state.perpage,this.state.ActivePage, value);
        this.props.listchartdata(this.state.activeFilter);

    }

    render() {
        const sortbystagefilter = [
            {"label": "All", "value": "all"},
            {"label": "introduction", "value": "introduction"},
            {"label": "primary-details", "value": "primary-details"},
            {"label": "verify-otp", "value": "verify-otp"},
            {"label": "fatca-pep", "value": "fatca-pep"},
            {"label": "Account exist in T24", "value": "Account exist in T24"},
            {"label": "id-scan", "value": "id-scan"},
            {"label": "id-scan-complete", "value": "id-scan-complete"},
            {"label": "wrong-id-error", "value": "wrong-id-error"},
            {"label": "extract-id-error", "value": "extract-id-error"},
            {"label": "passport-scan", "value": "passport-scan"},
            {"label": "passport-scan-complete", "value": "passport-scan-complete"},
            {"label": "wrong-passport-error", "value": "wrong-passport-error"},
            {"label": "extract-passport-error", "value": "extract-passport-error"},
            {"label": "passport-scan-complete", "value": "passport-scan-complete"},
            {"label": "liveness-info", "value": "liveness-info"},
            {"label": "liveness-gestures", "value": "liveness-gestures"},
            {"label": "user-details", "value": "user-details"},
            {"label": "personal-details", "value": "personal-details"},
            {"label": "employment-details", "value": "employment-details"},
            {"label": "residence-details", "value": "residence-details"},
            {"label": "employment-details", "value": "employment-details"},
            {"label": "preview", "value": "preview"},
            {"label": "thankyou", "value": "thankyou"}];
        let resultdata = this.props.dashboarddata ||[]
        let totalCount = this.props.dashboardcount ||0;
        let graphval = this.props.chartdata || []
        const weeklytotalCount = graphval.reduce((acc, obj) => acc + obj.count, 0);
        //console.log(graphval)
        let headline = this.state.activeFilter.charAt(0).toUpperCase() + this.state.activeFilter.slice(1);
        return (

            <React.Fragment>

                <Pagesidebar menu="Home" mainmenu="Dashboard" showsidebar={true} />
                <div className="pageSidebarOffset">
                    <navbar className="pageNav">
                        <Navbar pagettl="Dashboard"  showsidebar={true} filter="No" search="No"   />
                    </navbar>
                    {this.props.loading &&
                    <Loader/>
                    }

                    <div className={`paddingBlock ${graphval.length > 0 ? '-paddingBlock--heighted' : ''}`}>

                        <div className="flexCE">
                            <div className="capsuleBlock">
                                <div className="capsuleBlock__content noPadding cursorP">
                                    <span className={`sortText ${this.state.activeFilter === 'completed' ? 'active' : ""} `}  onClick={() => this.chooseFilter('completed')} >Completed</span>
                                </div>
                                <div className="capsuleBlock__content noPadding cursorP">
                                    <span className= {`sortText ${this.state.activeFilter === 'abandoned' ? 'active' : ""} `} onClick={() => this.chooseFilter('abandoned')} >Abandoned</span>
                                </div>
                            </div>
                        </div>

                        <div className="fullFlexBox fullHeight">
                            <div className="partitionFlexBox fullHeight flex-d-c">
                                {graphval && graphval.length > 0 ?
                                <div className="barchartblock">
                                    <h4 className="barcharthead"> Applications {this.state.activeFilter} this week ({weeklytotalCount})</h4>
                                    <div className="fullBlock fullHeight maxHeighted barchart">

                                            <ResponsiveContainer>
                                                <BarChart data={graphval.length > 0 ? graphval : []}
                                                          margin={{top: 20, right: 30, left: 20, bottom: 5}}>
                                                    <XAxis dataKey="date"
                                                           style={{stroke: '#E5EBF2', color: '#E5EBF2', height: '1px'}}/>
                                                    <Bar isAnimationActive={false} type="monotone" dataKey="count"
                                                         barSize={30}
                                                         fill='#9fd4fa' position="inside">
                                                        {graphval.length > 0 && graphval.map((entry, index) => (
                                                            <Cell key={index}/>
                                                        ))}
                                                        <LabelList dataKey="count" position="top"
                                                                   style={{fill: '#848484', fontSize: '12'}}/>
                                                    </Bar>

                                                </BarChart>
                                            </ResponsiveContainer>

                                    </div>
                                </div>:
                                    <></>
                                }
                                <div className='sortBox'>

                                    <div className='flexCS fullPanel'>
                                        <h4 className="headline">Total {headline} ({totalCount}) </h4>
                                        <div className="sortBox__check">

                                            <label className="csCheckbox">
                                                <input type="checkbox"  className="csCheckbox__input"  checked={this.state.nationality  === 'all' ? 'checked' : '' }   name ="nationality" onClick={() => this.chooseNationality('all')}  />
                                                <span className="csCheckbox__placeholder" />
                                            </label>
                                            <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:"All"}}></label>
                                        </div>
                                        <div className="sortBox__check">

                                            <label className="csCheckbox">
                                                <input type="checkbox"  className="csCheckbox__input"  checked={this.state.nationality  === 'resident' ? 'checked' : '' } name ="nationality" onClick={() => this.chooseNationality('resident')}  />
                                                <span className="csCheckbox__placeholder" />
                                            </label>
                                            <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:"Resident"}}></label>
                                        </div>
                                        <div className="sortBox__check">
                                            <label className="csCheckbox">
                                                <input type="checkbox"  name ="nationality" className="csCheckbox__input" checked={this.state.nationality  === 'nonresident' ? 'checked' : '' }  onClick={() => this.chooseNationality('nonresident')} />
                                                <span className="csCheckbox__placeholder" />
                                            </label>
                                            <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:"Non Resident"}}></label>

                                        </div>
                                        {this.state.activeFilter === 'abandoned' &&
                                        <div className="sortBox__check flexCB csLabeled">
                                            <label className="csForm__item__label">Stage :</label>
                                            <Selectbox label="Stage filter" name="stageFilter" labelStatus="No"
                                                       values={sortbystagefilter} onChange={this.handlestageFilter} value={this.state.activeStage}/>
                                        </div>
                                        }
                                    </div>
                                    {totalCount > this.state.perpage &&
                                    <div style={{padding: '0px 30px'}}>
                                        <Pagination
                                            activePage={this.state.ActivePage}
                                            itemsCountPerPage={this.state.perpage}
                                            totalItemsCount={totalCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                    }


                                </div>


                                <div className="tableWrapper scrollable heighted ">
                                    <table className="csTable">
                                        <thead>
                                        <tr>
                                            <th>Sl No</th>

                                            <th>Name</th>
                                            {this.state.activeFilter === 'completed' &&
                                            <th>CIF</th>
                                            }
                                            <th>Nationality</th>
                                            <th>Mobile</th>
                                            <th>Email</th>
                                            {this.state.activeFilter === 'abandoned' &&
                                            <>
                                                <th>Stage</th>
                                                <th>Failure Reason</th>
                                            </>
                                            }
                                            <th>Journey Id</th>
                                            <th>Created At</th>
                                            {this.state.activeFilter === 'completed' &&
                                                <>
                                                <th>DOB</th>
                                                <th>Gender</th>
                                                <th>National ID</th>
                                                <th>Branch Name</th>
                                                <th>Branch Code</th>
                                                </>
                                            }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {resultdata.length > 0 && resultdata.map((list,index) =>
                                            <tr key={`tr-${index}`}>
                                                <td>{index + 1}</td>

                                                <td>{list.name}</td>
                                                {this.state.activeFilter === 'completed' &&
                                                <td>{list.cif}</td>
                                                }
                                                <td>{list.nationality}</td>
                                                <td>{list.mobile}</td>
                                                <td>{list.email}</td>
                                                {this.state.activeFilter === 'abandoned' &&
                                                <>
                                                    <td>{list.stage}</td>
                                                    <td>{list.failure_reason}</td>
                                                </>
                                                }
                                                <td>{list.journey_id}</td>
                                                <td>{list.created_at}</td>
                                                {this.state.activeFilter === 'completed' &&
                                                <>
                                                    <td>{list.dob}</td>
                                                    <td>{list.gender}</td>
                                                    <td>{list.national_id}</td>
                                                    <td>{list.branch_name}</td>
                                                    <td>{list.branch_code}</td>
                                                </>
                                                }
                                            </tr>
                                        )}

                                        {resultdata.length == 0 &&
                                        <tr>

                                            <td colSpan="10" style={{textAlign:'center'}}>No record found</td>

                                        </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>

            </React.Fragment>

        )
    }
}
const mapStateToProps = state => ({
    dashboarddata: state.UserReducer.dashboarddata,
    dashboardcount: state.UserReducer.dashboardcount,
    chartdata: state.UserReducer.listchartsdat,
    loading: state.UserReducer.loading

});
export default  withRouter(connect(mapStateToProps, {
    listboarddata:userActions.ListDashboard,
    listchartdata:userActions.ListChart

})(Dashboard));
