import React, {useState, useEffect } from 'react'
import {Link, useHistory} from 'react-router-dom';
import { UserNewLogin} from "../actions/Authorization";

import {useDispatch, useSelector} from "react-redux";
import logo from "../assets/images/logo.png";
import Loader from "../components/Loader";



const Login = (props) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errormsg, setErrorMsg] = useState('');
    const [errorusername, setErrorUsername] = useState(false);
    const [errorpassword, setErrorPassword] = useState(false);
    const [pageloader, setPageloader] = useState(false);
    const logindatasuccess = useSelector(state => state.UserReducer.logindatatest  ) ;
    const [version, setVersion] = useState(false);
    //console.log(logindatasuccess);

    let history = useHistory();
    const dispatch = useDispatch();

    const showVersion = () =>{
        setVersion(!version) ;
    }

    const formHandler = (e) => {
        if(e.target.name === "username") {
            setUsername(e.target.value)
        }
        if(e.target.name === "password") {
            setPassword(e.target.value)

        }
    }


    const Login = (e) => {
        e.preventDefault();

        if( username !== '' && password !== '') {

            if(username !== 'admin' || password !== 'Muscat@1212'){
                setErrorUsername(false);
                setErrorPassword(false);
                setErrorMsg('Please provide valid login credentials');
            }else{
                setErrorUsername(false);
                setErrorPassword(false);
                dispatch(UserNewLogin(username,password)) ;
            }

        }else {
            if(username === ''){
                setErrorUsername(true);
            }
            if(password === ''){
                setErrorPassword(true);
            }
        }
    };

    useEffect(() => {
        if(logindatasuccess === 'Success') {
            history.push('/dashboard');
        }
    },[logindatasuccess]);

//console.log(logindatasuccess);

        return (
            <div className="flexCC fullViewPort" onSubmit={Login} >
                <div className="w355">
                    {pageloader &&
                    <Loader/>
                    }
                    <Link  onClick={() => showVersion()}  className="logo">
                        <img src={logo} className="logoImage" alt="logo"/>
                    </Link>
                    <h1>LOGIN</h1>
                    <form className="csForm">
                        <div className="csForm__item">
                            <input type="text" name="username" className={`csForm__item__input ${errorusername ? 'error' : ''} `} placeholder="Username" onChange={formHandler}/>
                        </div>

                        <div className="csForm__item">
                            <input name="password" type="password" className={`csForm__item__input ${errorpassword ? 'error' : ''} `} placeholder="Password"  onChange={formHandler} />
                        </div>

                        <div className="csForm__item">
                            <button  name="password" className="btn btn--primaryG btn--capsule btn--inFull">Login</button>
                            <p className="error-message">{errormsg}</p>
                        </div>
                        {version &&
                        <p>v3.7</p>
                        }

                    </form>
                </div>
            </div>
        );
}

export default Login ;
